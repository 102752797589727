import { Icon, Link } from "@fluentui/react";
import './Error.css';
import { IEdmError } from "../models/IEdmError";
import { useDateTimeHelpersNoContext } from "../hooks/useDateTimeHelpers";

interface IErrorProps {
  message: string;
  details?: any;
  imageSrc?: string;
  fullscreen?: boolean;
  error?: IEdmError;
  showRetry?: boolean;
  icon?: "error" | "warning"
  retryCallback?: () => void;
}

function Error(props: IErrorProps) {
  const dateTimeHelpers = useDateTimeHelpersNoContext();
  let classes = props.fullscreen ? "full-page" : "inline";

  let message = props.message;
  let details: string | undefined = undefined;
  const icon = props.icon ? props.icon : "error";

  if (props.details) {
    details = props.details.toString();
  } else if (props.error?.stackTrace) {
    details = props.error.stackTrace;
  }

  return (
    <div className={classes}>
      <div className="message"><Icon iconName={icon} className={icon + "-icon"} /> {message}</div>
      {details &&
        <div className="details">{details}</div>
      }
      {props.imageSrc &&
        <div className="image"><img src={props.imageSrc}></img></div>
      }
      {props.showRetry === true &&
        <div className="retry-link">
          <Link onClick={props.retryCallback}>Retry</Link>
        </div>
      }
      {props.error &&
        <div className="errorFooter">
          {props.error?.correlationId &&
            <div className="correlationId">Correlation Id: {props.error.correlationId}</div>
          }
          {props.error?.timestamp &&
            <div className="timestamp">{dateTimeHelpers.GetLocalDateString(props.error.timestamp)}</div>
          }
        </div>
      }
    </div>
  );
}
export default Error;