import { useContext, createContext } from 'react';
import { IEdmContext } from './useEdm';

export const EdmContext = createContext<IEdmContext | null>(null);

// Use useEdmContext() instead of useContext() in components
// to avoid having to provide a default context value

export const useEdmContext = (): IEdmContext => {
  const contextValue = useContext(EdmContext);
  if (contextValue === null) {
    throw Error("No EDM context provider supplied!");
  }
  return contextValue;
};