import React from "react";
import { Icon } from '@fluentui/react';
import { FileIconType, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import EdmConstants from "./EdmConstants";

// Access nested properties with dot notation, e.g. getByPath( { a: { b: "hello"}, "a.b"} )
export function getByPath<T>(object: any, path: string): T | undefined {
  try {
    return path.split('.').reduce((acc, curr) => acc[curr], object);
  } catch {
    return undefined;
  }
}

export function toIsoDateString(date: Date) {
  // Return ISO date with reduced precision (stripped of millisecods)
  // This should be the same date format as we get from SharePoint
  return date.toISOString().slice(0, 19) + 'Z';
}

export function isRunningInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

// Filetypes & Icons

export function getIconForFile(filename: string, style: React.CSSProperties = {}): JSX.Element {
  // https://developer.microsoft.com/en-us/fluentui#/styles/web/file-type-icons
  let iconType = "genericfile";
  if (filename) {
    iconType = filename.split('.').pop()!;
  }
  return <Icon {...getFileTypeIconProps({ extension: iconType, size: 16, imageFileType: 'svg' })} style={style}></Icon>;
}

export function getIconForType(type: FileIconType, style: React.CSSProperties = {}): JSX.Element {
  // https://developer.microsoft.com/en-us/fluentui#/styles/web/file-type-icons
  return <Icon {...getFileTypeIconProps({ type: type, size: 16, imageFileType: 'svg' })} style={style}></Icon>;
}

const word = ["doc", "docm", "docx", "docb"];
const excel = ["xlc", "xls", "xlsb", "xlsm", "xlsx", "xlw"];
const powerpoint = ["ppt", "pptm", "pptx", "sldx", "sldm"];
const visio = ["vdx", "vsd", "vsdm", "vsdx", "vsw", "vdw"];

export function CanOpenDocumentInClientApp(fileUrl: string): boolean {
  const ext = fileUrl.split(".").pop()?.toLowerCase();
  if (!ext) return false;
  const knownExtensions = word.concat(excel).concat(powerpoint).concat(visio);
  return knownExtensions.includes(ext);
}

export function OpenDocumentInClientApp(fileUrl: string): boolean {

  /*
      Docs:
      https://learn.microsoft.com/en-us/office/client-developer/office-uri-schemes

      Syntax:
      < scheme-name >:< command-name >"|"< command-argument-descriptor > "|"< command-argument >

      Schemes:
      ms-word:
      ms-powerpoint:
      ms-excel:
      ms-visio:
      ms-access:
      ms-project:
      ms-publisher:
      ms-spd:
      ms-infopath:

      Commands:
      open-for-edit-cmd = "ofe|u|" document-uri
      open-for-view-cmd = "ofv|u|" document-uri
      new-from-template-cmd = "nft|u|" template-uri ["|s|" save-location]

      Example:
      ms-excel:ofv|u|<https://contoso/Q4/budget.xls>

      Note: Enclosing path in <> did not work for me, and O365 does not seem to use it either
  */

  const ext = fileUrl.split(".").pop()?.toLowerCase();

  if (!ext) return false;

  var link = "";
  if (word.includes(ext)) link = "ms-word:ofv|u|" + fileUrl;
  if (excel.includes(ext)) link = "ms-excel:ofv|u|" + fileUrl;
  if (powerpoint.includes(ext)) link = "ms-powerpoint:ofv|u|" + fileUrl;
  if (visio.includes(ext)) link = "ms-visio:ofv|u|" + fileUrl;

  if (link === "") return false;

  window.location.href = link;
  return true;
}

// Added instead of encodeURIComponent since langague specific characters should not be encoded for SharePoint file links.
function encodeForSP(str: string) {
  let encodedStr = str.replaceAll('$', '%24')
    .replaceAll('#', '%23')
    .replaceAll('@', '%40')
    .replaceAll('^', '%5E')
    .replaceAll('&', '%26')
    .replaceAll('[', '%5B')
    .replaceAll(']', '%5D');
  return encodedStr;
}

function encodeFilePath(fileUrl: string) {
  let pieces = fileUrl.split('/sites/');

  let host = pieces[0];
  let path = pieces[1];

  let pathPieces = path.split('/');

  let newFileUrl = host + '/sites';

  for (let index = 0; index < pathPieces.length; index++) {
    let piece = pathPieces[index];

    if (index === pathPieces.length - 1) {
      let fileNameNoExt = piece.slice(0, piece.lastIndexOf('.'));
      let ext = piece.split(fileNameNoExt)[1];

      newFileUrl += '/' + encodeForSP(fileNameNoExt) + ext;
    } else {
      newFileUrl += '/' + encodeForSP(piece);
    }
  }

  return newFileUrl;
}
export function GetDocumentLink(fileUrl: string): string {
  if (CanOpenDocumentInClientApp(fileUrl)) {
    return encodeFilePath(fileUrl) + '?web=1';
  } else {
    return encodeFilePath(fileUrl);
  }
}

export function ContainsIllegalCharacters(str: string, includeMultiValDelimiter: boolean = true) {
  let regexStr = EdmConstants.IllegalCharacters.join('');

  if (!includeMultiValDelimiter) {
    regexStr = regexStr.replaceAll("¤", '');
  }

  let regex = RegExp(`[${regexStr}]`);

  return regex.test(str);
}

export function GetEnvironmentToken(): string {
  return window.location.hostname.toLowerCase().includes("dev") ? "DEV" :
    window.location.hostname.toLowerCase().includes("test") ? "TEST" :
      window.location.hostname.toLowerCase().includes("staging") ? "STAGING" :
        window.location.hostname.toLowerCase().includes("localhost") ? "LOCAL" :
          "";
}



