import { Spinner, SpinnerSize } from "@fluentui/react";
import './Loading.css';

interface ILoadingProps {
  message?: string;
  fullscreen?: boolean;
}

function Loading(props: ILoadingProps) {

  const visible = true;

  let classes = props.fullscreen ? "full-page" : "inline";

  return (
    <div className={classes}>
      <Spinner className="spinner" size={SpinnerSize.large} label={props.message} style={visible ? { visibility: "visible" } : { visibility: "hidden" }} />
    </div>
  );
}
export default Loading;