import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import authConfig from './context/AuthConfig';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { BrowserRouter as Router } from "react-router-dom";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
// Fluent UI Core + Icons
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
// MSAL
export const msalInstance = new PublicClientApplication(authConfig.msal);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Application Insights
// To track events and more, see: https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
var reactPlugin = new ReactPlugin();
try {
  var appInsights = new ApplicationInsights({
    config: {
      connectionString: window.EDMCONTEXT.ApplicationInsightsConnectionString || process.env.REACT_APP_APPINSIGHTS,
      //enableAutoRouteTracking: true,    // can't get this to work properly - getting duplicate entries - so we are tracking manually instead - see Layout.tsx
      extensions: [reactPlugin],
    }
  });
  appInsights.loadAppInsights();
} catch {
  console.warn("Application Insights could not initialize")
}


initializeIcons();
initializeFileTypeIcons(/* optional base url */);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (window.location.hash !== '' && window.opener) {
  // This prevents Reacts router from removing the # value returned from the popup login flow which occasionaly happens otherwise (race condition).
  // More info: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5091
} else {
  // NOTE: Strict mode causes issues with Fluent UI:
  //        DetailsList: column resizing (auto and manual), item selection, possibly more
  //        Pickers (used in PeoplePicker): Search is not triggered (https://github.com/microsoft/fluentui/issues/25441)
  root.render(
    // <React.StrictMode>
    <Router>
      <AppInsightsContext.Provider value={reactPlugin}>
        <App pca={msalInstance} />
      </AppInsightsContext.Provider>
    </Router>
    // </React.StrictMode>
  )
};