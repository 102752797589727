import { useEdmErrorContext } from '../context/useEdmErrorContext';
import Error from './Error';

interface IErrorBoundaryFallbackProps {
  error: any;
  resetErrorBoundary: any;
}

function ErrorBoundaryFallback(props: IErrorBoundaryFallbackProps) {
    const errorCtx = useEdmErrorContext();

  return (
    <Error error={errorCtx.currentClientError} message='Sorry something went wrong...' fullscreen={true}></Error>
  );
}
export default ErrorBoundaryFallback;