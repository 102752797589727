
const EdmConstants = {

  // SharePoint
  EdmBaseContentTypeId: "0x010100ED50F5B1D75948D9A930CCE259B87CBC",  // The base content type that all other inherit from
  EdmCoreStaticFieldPrefix: "EdmCore",
  EdmCoreDocSetDocumentTypeKeu: "EdmDocSet",

  // Internal Field Names
  Field_EdmCoreSystem: "EdmCoreSystem",
  Field_EdmCoreCountry: "EdmCoreCountry",
  Field_EdmCoreEntityType: "EdmCoreEntityType",
  Field_EdmCoreEntityId: "EdmCoreEntityId",
  Field_EdmCoreStatus: "EdmCoreStatus",
  Field_EdmCoreDocTypeGroup: "EdmCoreDocTypeGroup",
  Field_EdmCoreDocType: "EdmCoreDocType",
  Field_EdmCoreComment: "EdmCoreComment",
  Field_EdmCoreObsolete: "EdmCoreObsolete",
  Field_EdmCoreVersion: "EdmCoreVersion",
  Field_EdmCoreCopyOf: "EdmCoreCopyOf",
  Field_EdmCoreLanguage: "EdmCoreLanguage",

  Field_EdmCorePubLineage: "EdmCorePubLineage",
  Field_EdmPublishingApprover: "EdmCorePubApprover",
  Field_EdmPublishingRequester: "EdmCorePubRequester",
  Field_EdmPublishingDate: "EdmCorePubDueDate",
  Field_EdmPublishingComments: "EdmCorePubComments",

  Field_SpContentTypeId: "ContentTypeId",
  Field_SpContentType: "ContentType",
  Field_SpModifiedBy: "Editor",
  Field_SpModified: "Modified",
  Field_SpCreatedBy: "Author",
  Field_SpCreated: "Created",
  Field_EdmDecEmploymentStartDate: "EdmDecEmploymentStartDate",
  Field_EdmDecContractSignedDate: "EdmDecContractSignedDate",
  Field_EdmDecEmploymentEndDate: "EdmDecEmploymentEndDate",

  // "Field_EdmCoreStatus" values
  Status_Working: "0",
  Status_Pending: "1",
  Status_Published: "2",

  // Roles
  AccessToAdmin: "Admin",
  AccessToTranslate: "Translate",

  // Illegal Characters. If anything is changed here update the corresponding list in EdmConstants.cs in the API.
  IllegalCharacters: ['¤', '%'],

  // All Except External Users (not containing the claims or the tenant id)
  EveryoneExceptExternal: "spo-grid-all-users",
}
export default EdmConstants;
