
import { createContext, useContext, useState } from "react";
import IEdmError from "../models/IEdmError";

export interface IEdmErrorContext {
  currentClientError: IEdmError | undefined;
  setCurrentClientError: (error: IEdmError | undefined) => void;
}

export function useEdmError(): IEdmErrorContext {

  const [currentClientError, setCurrentClientError] = useState<IEdmError | undefined>(undefined);

  const edmErrorContext: IEdmErrorContext = {
    currentClientError: currentClientError,
    setCurrentClientError: setCurrentClientError
  };

  return edmErrorContext;
}

export const EdmErrorContext = createContext<IEdmErrorContext | null>(null);

export const useEdmErrorContext = (): IEdmErrorContext => {
  const contextValue = useContext(EdmErrorContext);
  if (contextValue === null) {
    throw Error("No EDM error context provider supplied!");
  }
  return contextValue;
};

