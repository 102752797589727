import { Configuration, InteractionType } from "@azure/msal-browser";
import { isRunningInIframe } from "../utils/Utils";

interface IEdmContext {
  ApiUrl: string;
  ClientId: string;
  TenantId: string;
  GraphUserAgent: string;
  ApiScope: string;
  Version: string;
  TrainingPortalUrl: string;
  ApplicationInsightsConnectionString: string;
}

// Declare properties on window object to make typescript compile
declare global {
  interface Window {
    EDMCONTEXT: IEdmContext;
    setDebugMode: (enabled: boolean) => void;
  }
}

/*
Some words about the configuration
  - window.EDMCONTEXT is defined in index.html
  - When loading index.html from the API it will fill out the configuration
  - When running the client with "npm start" in e.g. VS Code you do not get these settings. Instead configure settings like this:
     - Create a file called ".env.development.local"
     - Paste the following:

REACT_APP_APIURL=https://localhost:7000/api
REACT_APP_TENANTID=[TENANTID]
REACT_APP_CLIENTID=[CLIENT APP CLIENTID]
REACT_APP_USERAGENT=NONISV|Securitas|EDMClient/Dev
REACT_APP_APISCOPE=api://[API APP CLIENTID]/EDM.Access
REACT_APP_APPINSIGHTS=[APP INSIGHTS CONNECTION STRING]
*/

if (process.env.REACT_APP_APIURL) window.EDMCONTEXT.ApiUrl = process.env.REACT_APP_APIURL;
if (process.env.REACT_APP_TENANTID) window.EDMCONTEXT.TenantId = process.env.REACT_APP_TENANTID;
if (process.env.REACT_APP_CLIENTID) window.EDMCONTEXT.ClientId = process.env.REACT_APP_CLIENTID;
if (process.env.REACT_APP_USERAGENT) window.EDMCONTEXT.GraphUserAgent = process.env.REACT_APP_USERAGENT;
if (process.env.REACT_APP_APISCOPE) window.EDMCONTEXT.ApiScope = process.env.REACT_APP_APISCOPE;
if (process.env.REACT_APP_VERSION) window.EDMCONTEXT.Version = process.env.REACT_APP_VERSION;
if (process.env.REACT_APP_TRAININGPORTALURL) window.EDMCONTEXT.TrainingPortalUrl = process.env.REACT_APP_TRAININGPORTALURL;
if (process.env.REACT_APP_APPINSIGHTS) window.EDMCONTEXT.ApplicationInsightsConnectionString = process.env.REACT_APP_APPINSIGHTS;

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// MSAL Configuration: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
const msalConfig: Configuration = {
  auth: {
    // local dev:
    clientId: window.EDMCONTEXT.ClientId,
    authority: `https://login.microsoftonline.com/${window.EDMCONTEXT.TenantId}/`,
    redirectUri: "/"
    //postLogoutRedirectUri: "/"
  },
  cache: {
    //cacheLocation: "localStorage",
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  }
};

const graphConfig = {
  url: "https://graph.microsoft.com/v1.0",
  scopes: [   // note sure we really need to specify everything here since they are already admin granted
    "User.Read",
    "Directory.Read.All",       // <PeoplePicker>, <UserInGroupPicker>
    "People.Read"              // <PeoplePicker>
  ],
  userAgent: window.EDMCONTEXT.GraphUserAgent
}

const apiConfig = {
  url: window.EDMCONTEXT.ApiUrl,
  scopes: [
    window.EDMCONTEXT.ApiScope
  ]
}

const loginMode = isRunningInIframe() ? InteractionType.Popup : InteractionType.Redirect;

const authConfig = {
  msal: msalConfig,
  graph: graphConfig,
  api: apiConfig,
  loginMode: loginMode,
}

export const version = window.EDMCONTEXT.Version;
export const trainingportalurl = window.EDMCONTEXT.TrainingPortalUrl;

export default authConfig;
