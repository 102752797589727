import './App.css';
import { MsalAuthenticationResult, MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import Loading from './components/Loading';
import Error from './components//Error';
import authConfig from "./context/AuthConfig";
//import { useMsgBox } from './components/dialogs/MsgBoxDialog';
import { EdmErrorContext, useEdmError } from './context/useEdmErrorContext';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { lazy } from "react";

const EdmApp = lazy(() => import("./EdmApp"))

type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {

  //const msgBox = useMsgBox();

  function AuthErrorComponent(auth: MsalAuthenticationResult) {
    console.error("MsalAuthenticationTemplate Error:", auth.error)

    function retryFunc() {
      if (authConfig.loginMode === InteractionType.Popup)
        pca.loginPopup();
      else
        pca.loginRedirect();
    }

    if (auth.error?.errorCode === "popup_window_error") {
      return <Error message="Please allow popups to login to EDM" showRetry={true} retryCallback={retryFunc} imageSrc="./AllowPopups.png" fullscreen={true}></Error>;
    } else {
      return <Error message="Authentication Error" showRetry={true} retryCallback={retryFunc} details={auth.error?.errorMessage} fullscreen={true}></Error>;
    }

  }

  function AuthLoadingComponent() {
    return <Loading message="Authenticating..." fullscreen={true}></Loading>;
  }

  /*
    iFrame:
      https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/iframe-usage.md
      Min förståelse: Redirect fungerar inte med iFrame
      Måste använda PopUp, men den kan vara blockad
      För sso: använd silent + loginhint
      Men om inte loginhint finns kommer silent inte fungera
      MsalAuthenticationTemplate som jag använder ger inga direkta fel om den misslyckas i silent - kanske måste bygga eget?
  */
  //const loginHint = "megan@81ys3y.onmicrosoft.com"
  //const loginHint = "dan@81ys3y.onmicrosoft.com"
  //username={loginHint} 


  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: 5 * 60 * 1000, // 5 min.
        retry: 3
      },
      mutations: {
        retry: (failureCount, errorObj) => {
          // Max retries reached.
          if (failureCount >= 3) {
            return false;
          }

          let error = errorObj as any;

          // Here we prevent retries on specific error status codes from the api.
          // Conflict (409) for example should never be retried since it cannot succeed.
          if (error.httpStatus) {
            if (error.httpStatus === 409) {
              return false;
            }
          }

          return true
        }
      }
    }
  })

  // Setting up a error context for supplying error boundaries etc 
  // with correlation / trace ids generated from the client logger.
  const edmErrors = useEdmError();

  return (
    <EdmErrorContext.Provider value={edmErrors}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={(error: any) => { console.error(error) }}>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate interactionType={authConfig.loginMode} errorComponent={AuthErrorComponent} loadingComponent={AuthLoadingComponent}>
              <EdmApp />
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </QueryClientProvider>
        {/* {msgBox.JSX} */}
      </ErrorBoundary>
    </EdmErrorContext.Provider>
  );
}

export default App;

