import dayjs from "dayjs";
import 'dayjs/locale/tr';
import 'dayjs/locale/nn';
import * as utcPlugin from 'dayjs/plugin/utc';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { useEdmContext } from "../context/useEdmContext";
import { useEffect, useState } from "react";

export interface IDateTimeHelpers {
  Now: () => string;
  UTCNow: () => string;
  GetLocalDateString: (dateString: string) => string;
  CurrentMonth: () => string;
  CurrentYear: () => number;
  CurrentDayInMonth: () => number;
  CurrentDateString: () => string;
  GetLocalizedDateString: (dateString: string) => string;
}

export interface IDateTimeHelpersNoContext {
  Now: () => string;
  UTCNow: () => string;
  GetLocalDateString: (dateString: string) => string;
}

export function useDateTimeHelpers(): IDateTimeHelpers {
  const [language, setLanguage] = useState<string>("en");
  
  const noCtxHelper = useDateTimeHelpersNoContext();
  const ctx = useEdmContext();

  dayjs.locale(language);
  dayjs.extend(utcPlugin as any);
  dayjs.extend(localizedFormat as any);

  const currentDate = dayjs();

  function currentDayInMonth() {
    return parseInt(currentDate.format("D"));
  }

  function currentMonth() {
    return currentDate.format("MMMM");
  }

  function currentYear() {
    return currentDate.year();
  }

  function currentDateString() {
    return currentDate.format("LLLL");
  }

  function getLocalizedDateString(dateString: string) {
    let date = dayjs(dateString);

    return date.format("L");
  }
  
  useEffect(() => {
    if(ctx.locale === 'no') { // Norwegian language code is not the same as the country code hence this check.
      setLanguage('nn');
    } else if(ctx.locale){
      setLanguage(ctx.locale);
    } else {
      setLanguage('en');
    }
  },[ctx.locale]);
  
  return {
    Now: noCtxHelper.Now,
    UTCNow: noCtxHelper.UTCNow,
    GetLocalDateString: noCtxHelper.GetLocalDateString,
    CurrentMonth: currentMonth,
    CurrentYear: currentYear,
    CurrentDayInMonth: currentDayInMonth,
    CurrentDateString: currentDateString,
    GetLocalizedDateString: getLocalizedDateString
  }
}

export function useDateTimeHelpersNoContext(): IDateTimeHelpersNoContext {
  dayjs.extend(utcPlugin as any);

  const currentDate = dayjs();

  function now() {
    return currentDate.toISOString();
  }
  
  function utcNow() {
    return currentDate.utc().toISOString();
  }

  function getLocalDateString(dateString: string){
    return new Date(dateString).toLocaleString();
  }
  
  return {
    Now: now,
    UTCNow: utcNow,
    GetLocalDateString: getLocalDateString
  }
}